import { Button, Card, Col, Divider, Row, Tooltip, Typography } from "antd";
import ButtonShowCode from "../Components/ButtonShowCode";
import { CodeBlock, dracula } from "react-code-blocks";
import { codeTooltip } from "./ComponentCodeBox";

const text = <span>prompt text</span>;
const buttonWidth = 70;

export default function PagePopups() {
	return (
		<Card className="page-component-popups">
			<Row gutter={[12, 12]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					More on:{" "}
					<Typography.Link
						href="https://ant.design/components/tooltip/"
						target="new"
					>
						https://ant.design/components/tooltip/
					</Typography.Link>
				</Col>
				<Col xs={24} sm={24} md={24} lg={24} xl={12}>
					<Card>
						<div className="demo">
							<div
								style={{
									marginLeft: buttonWidth,
									whiteSpace: "nowrap",
								}}
							>
								<Tooltip placement="topLeft" title={text}>
									<Button>TL</Button>
								</Tooltip>
								<Tooltip placement="top" title={text}>
									<Button>Top</Button>
								</Tooltip>
								<Tooltip placement="topRight" title={text}>
									<Button>TR</Button>
								</Tooltip>
							</div>
							<div
								style={{
									width: buttonWidth,
									float: "left",
								}}
							>
								<Tooltip placement="leftTop" title={text}>
									<Button>LT</Button>
								</Tooltip>
								<Tooltip placement="left" title={text}>
									<Button>Left</Button>
								</Tooltip>
								<Tooltip placement="leftBottom" title={text}>
									<Button>LB</Button>
								</Tooltip>
							</div>
							<div
								style={{
									width: buttonWidth,
									marginLeft: buttonWidth * 4 + 24,
								}}
							>
								<Tooltip placement="rightTop" title={text}>
									<Button>RT</Button>
								</Tooltip>
								<Tooltip placement="right" title={text}>
									<Button>Right</Button>
								</Tooltip>
								<Tooltip placement="rightBottom" title={text}>
									<Button>RB</Button>
								</Tooltip>
							</div>
							<div
								style={{
									marginLeft: buttonWidth,
									clear: "both",
									whiteSpace: "nowrap",
								}}
							>
								<Tooltip placement="bottomLeft" title={text}>
									<Button>BL</Button>
								</Tooltip>
								<Tooltip placement="bottom" title={text}>
									<Button>Bottom</Button>
								</Tooltip>
								<Tooltip placement="bottomRight" title={text}>
									<Button>BR</Button>
								</Tooltip>
							</div>
						</div>
						<Divider className="m-b-xs" orientation="left" plain>
							Placement
						</Divider>

						<div className="text-center">
							<ButtonShowCode id="divCodeModal" />
						</div>

						<div id="divCodeModal" className="hide">
							<Divider className="m-t-xs m-b-sm" />

							<CodeBlock
								text={codeTooltip}
								language="jsx"
								showLineNumbers={false}
								theme={dracula}
								codeBlock
								className="component-pre"
								code
							/>
						</div>
					</Card>
				</Col>
				<Col xs={24} sm={24} md={24} lg={24} xl={12}>
					{/* <Card></Card> */}
				</Col>
			</Row>
		</Card>
	);
}
