import React from "react";
import { Card, Col, Divider, Row, Typography } from "antd";
import ButtonShowCode from "../Components/ButtonShowCode";
import { CodeBlock, dracula } from "react-code-blocks";
import { codeAdminEnv, codeUiEnv } from "./ComponentCodeBox";

export default function PageGettingStarted() {
	return (
		<Card>
			<Row gutter={[12, 12]}>
				<Col xs={24} sm={24} md={24}>
					<Typography.Title level={1} className="m-b-none">
						Install and Initialization
					</Typography.Title>
					<br />
					git clone{" "}
					<Typography.Link
						href="https://github.com/cleggy28/shamcey_theme_setup.git"
						target="new"
					>
						https://github.com/cleggy28/shamcey_theme_setup.git
					</Typography.Link>
					<br />
					Setup <b>ENV</b> for UI: <ButtonShowCode id="divCodeUiEnv" />
					<div id="divCodeUiEnv" className="hide">
						<Divider className="m-t-xs m-b-sm" />

						<CodeBlock
							text={codeUiEnv}
							language="jsx"
							showLineNumbers={false}
							theme={dracula}
							codeBlock
							className="component-pre"
							code
						/>
					</div>
					<br />
					Open <b>CMD</b>, cd to the root folder, then run:
					<br />
					<b>npm install</b>
					<br />
					<b>npm install --save-dev</b>
					<br />
					then, <b>npm start</b>
					<br />
					Setup <b>ENV</b> for Admin folder:{" "}
					<ButtonShowCode id="divCodeAdminEnv" />
					<div id="divCodeAdminEnv" className="hide">
						<Divider className="m-t-xs m-b-sm" />

						<CodeBlock
							text={codeAdminEnv}
							language="jsx"
							showLineNumbers={false}
							theme={dracula}
							codeBlock
							className="component-pre"
							code
						/>
					</div>
					<br />
					Setup <b>Database</b>
					<br />
					Open another <b>CMD</b>, cd to the root folder /admin, then run:{" "}
					<b>composer install</b>
					<br />
					then after, run <b>php artisan serve --port=9002</b>
					<br />
					<Typography.Title level={2} className="m-t-md">
						HAPPY HACKING! ! !
					</Typography.Title>
				</Col>
				<Col xs={24} sm={24} md={24}></Col>
				<Col xs={24} sm={24} md={24}></Col>
			</Row>
		</Card>
	);
}
