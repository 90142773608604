import React from "react";
import { Card, Col, Divider, Row, Typography } from "antd";
import { CodeBlock, dracula } from "react-code-blocks";
import ButtonShowCode from "../Components/ButtonShowCode";
import { codeBasicCard, codeCustomizedContent } from "./ComponentCodeBox";

export default function PageCards() {
	return (
		<Card className="page-component-alert">
			<Row gutter={[12, 12]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					More on:{" "}
					<Typography.Link
						href="https://ant.design/components/card/"
						target="new"
					>
						https://ant.design/components/card/
					</Typography.Link>
				</Col>
				<Col xs={24} sm={24} md={24} lg={24} xl={12}>
					<Card>
						<Card
							title="Main Card"
							extra={
								<a href="#!" style={{ color: "#fff" }}>
									More
								</a>
							}
							style={{
								width: "100%",
							}}
							className="card-main"
						>
							<p>Card Main</p>
							<p>Card Main</p>
							<p>Card Main</p>
						</Card>

						<Card
							title="Primary Card"
							extra={
								<a href="#!" style={{ color: "#fff" }}>
									More
								</a>
							}
							style={{
								width: "100%",
							}}
							className="m-t-lg card-primary-head"
						>
							<p>Card content</p>
							<p>Card content</p>
							<p>Card content</p>
						</Card>
						<Card
							title="Secondary Card"
							extra={
								<a href="#!" style={{ color: "#fff" }}>
									More
								</a>
							}
							style={{
								width: "100%",
							}}
							className="m-t-lg card-secondary-head"
						>
							<p>Card content</p>
							<p>Card content</p>
							<p>Card content</p>
						</Card>

						<Card
							title="Success Card"
							extra={
								<a href="#!" style={{ color: "#fff" }}>
									More
								</a>
							}
							style={{
								width: "100%",
							}}
							className="m-t-lg card-success-head"
						>
							<p>Card content</p>
							<p>Card content</p>
							<p>Card content</p>
						</Card>

						<Card
							title="Warning Card"
							extra={
								<a href="#!" style={{ color: "#fff" }}>
									More
								</a>
							}
							style={{
								width: "100%",
							}}
							className="m-t-lg card-warning-head"
						>
							<p>Card content</p>
							<p>Card content</p>
							<p>Card content</p>
						</Card>

						<Card
							title="Danger Card"
							extra={
								<a href="#!" style={{ color: "#fff" }}>
									More
								</a>
							}
							style={{
								width: "100%",
							}}
							className="m-t-lg card-danger-head"
						>
							<p>Card content</p>
							<p>Card content</p>
							<p>Card content</p>
						</Card>

						<Card
							title="Info Card"
							extra={
								<a href="#!" style={{ color: "#fff" }}>
									More
								</a>
							}
							style={{
								width: "100%",
							}}
							className="m-t-lg card-info-head"
						>
							<p>Card content</p>
							<p>Card content</p>
							<p>Card content</p>
						</Card>

						<Divider className="m-b-xs" orientation="left" plain>
							Basic card
						</Divider>

						<div className="text-center">
							<ButtonShowCode id="divCodeBasicCard" />
						</div>

						<div id="divCodeBasicCard" className="hide">
							<Divider className="m-t-xs m-b-sm" />

							<CodeBlock
								text={codeBasicCard}
								language="jsx"
								showLineNumbers={false}
								theme={dracula}
								codeBlock
								className="component-pre"
								code
							/>
						</div>
					</Card>
				</Col>
				<Col xs={24} sm={24} md={24} lg={24} xl={12}>
					<Card>
						<Card
							hoverable
							style={{
								width: 240,
							}}
							cover={
								<img
									alt="example"
									src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
								/>
							}
						>
							<Card.Meta
								title="Europe Street beat"
								description="www.instagram.com"
							/>
						</Card>

						<Divider className="m-b-xs" orientation="left" plain>
							Customized Content
						</Divider>

						<div className="text-center">
							<ButtonShowCode id="divCodeCustomizedContent" />
						</div>

						<div id="divCodeCustomizedContent" className="hide">
							<Divider className="m-t-xs m-b-sm" />

							<CodeBlock
								text={codeCustomizedContent}
								language="jsx"
								showLineNumbers={false}
								theme={dracula}
								codeBlock
								className="component-pre"
								code
							/>
						</div>
					</Card>
				</Col>
				<Col xs={24} sm={24} md={24} lg={24} xl={12}></Col>
			</Row>
		</Card>
	);
}
