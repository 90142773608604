import { Card, Col, Divider, Row, Typography } from "antd";
import ButtonShowCode from "../Components/ButtonShowCode";
import { CodeBlock, dracula } from "react-code-blocks";
import { codeGrid } from "./ComponentCodeBox";
const style = {
	background: "#0092ff",
	padding: "8px 0",
	textAlign: "center",
	color: "#fff",
};

export default function PageGrid() {
	return (
		<Card className="page-component-grid">
			<Row gutter={[12, 12]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					More on:{" "}
					<Typography.Link
						href="https://ant.design/components/grid/"
						target="new"
					>
						https://ant.design/components/grid/
					</Typography.Link>
				</Col>
				<Col xs={24} sm={24} md={24} lg={24} xl={12}>
					<Card>
						<Divider orientation="left" className="m-t-none">
							Horizontal
						</Divider>
						<Row gutter={16}>
							<Col className="gutter-row" span={6}>
								<div style={style}>col-6</div>
							</Col>
							<Col className="gutter-row" span={6}>
								<div style={style}>col-6</div>
							</Col>
							<Col className="gutter-row" span={6}>
								<div style={style}>col-6</div>
							</Col>
							<Col className="gutter-row" span={6}>
								<div style={style}>col-6</div>
							</Col>
						</Row>
						<Divider orientation="left">Responsive</Divider>
						<Row
							gutter={{
								xs: 8,
								sm: 16,
								md: 24,
								lg: 32,
							}}
						>
							<Col className="gutter-row" span={6}>
								<div style={style}>col-6</div>
							</Col>
							<Col className="gutter-row" span={6}>
								<div style={style}>col-6</div>
							</Col>
							<Col className="gutter-row" span={6}>
								<div style={style}>col-6</div>
							</Col>
							<Col className="gutter-row" span={6}>
								<div style={style}>col-6</div>
							</Col>
						</Row>
						<Divider orientation="left">Vertical</Divider>
						<Row gutter={[16, 24]}>
							<Col className="gutter-row" span={6}>
								<div style={style}>col-6</div>
							</Col>
							<Col className="gutter-row" span={6}>
								<div style={style}>col-6</div>
							</Col>
							<Col className="gutter-row" span={6}>
								<div style={style}>col-6</div>
							</Col>
							<Col className="gutter-row" span={6}>
								<div style={style}>col-6</div>
							</Col>
							<Col className="gutter-row" span={6}>
								<div style={style}>col-6</div>
							</Col>
							<Col className="gutter-row" span={6}>
								<div style={style}>col-6</div>
							</Col>
							<Col className="gutter-row" span={6}>
								<div style={style}>col-6</div>
							</Col>
							<Col className="gutter-row" span={6}>
								<div style={style}>col-6</div>
							</Col>
						</Row>

						<Divider className="m-b-xs" orientation="left" plain>
							Grid Gutter
						</Divider>

						<div className="text-center">
							<ButtonShowCode id="divCodeGrid" />
						</div>

						<div id="divCodeGrid" className="hide">
							<Divider className="m-t-xs m-b-sm" />

							<CodeBlock
								text={codeGrid}
								language="jsx"
								showLineNumbers={false}
								theme={dracula}
								codeBlock
								className="component-pre"
								code
							/>
						</div>
					</Card>
				</Col>
				<Col xs={24} sm={24} md={24} lg={24} xl={12}>
					<Card></Card>
				</Col>
			</Row>
		</Card>
	);
}
