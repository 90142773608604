import { faFlag } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Typography } from "antd";

export const menuLeft = (
	<>
		<div className="ant-menu-left-icon">
			<Typography.Link href="https://fontawesome.com/v6/search" target="new">
				<span className="anticon">
					<FontAwesomeIcon icon={faFlag} />
				</span>
				<Typography.Text>Font Awesome</Typography.Text>
			</Typography.Link>
		</div>
	</>
);

export const dropDownMenuLeft = () => {
	const items = [
		{
			key: "https://fontawesome.com/v6/search",
			icon: <FontAwesomeIcon icon={faFlag} />,
			label: (
				<Typography.Link href="https://fontawesome.com/v6/search" target="new">
					Font Awesome
				</Typography.Link>
			),
		},
	];

	return <Menu items={items} />;
};
