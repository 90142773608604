export const codeFloatInput = `import { Form } from "antd";
import FloatInput from "./providers/FloatInput";

const App = () => {
	const [form] = Form.useForm();

	const onFinish = (values) => {
		console.log("onFinish", values);
	};

	return (
		<Form form={form} onFinish={onFinish}>
			<Form.Item name="input">
				<FloatInput label="Input" placeholder="Input" />
			</Form.Item>
		</Form>
	);
};
export default App;`;

export const codeFloatSelectCode = `import { Form } from "antd";
import FloatSelect from "./providers/FloatSelect";

const App = () => {
	const [form] = Form.useForm();

	const onFinish = (values) => {
		console.log("onFinish", values);
	};

	return (
		<Form form={form} onFinish={onFinish}>
			<Form.Item name="select" className="form-select-error">
				<FloatSelect
					label="Select"
					placeholder="Select"
					options={[
						{
							value: "Option 1",
							label: "Option 1",
						},
						{
							value: "Option 2",
							label: "Option 2",
						},
						{
							value: "Option 3",
							label: "Option 3",
						},
					]}
				/>
			</Form.Item>
		</Form>
	);
};
export default App;`;

export const codeFloatInputMask = `import { Form } from "antd";
import FloatInputMask from "./providers/FloatInputMask";

const App = () => {
	const [form] = Form.useForm();

	const onFinish = (values) => {
		console.log("onFinish", values);
	};

	return (
		<Form form={form} onFinish={onFinish}>
			<Form.Item name="phone_number">
				<FloatInputMask
					label="Phone Number"
					placeholder="Phone Number"
					maskLabel="phone_number"
					maskType="999 999 9999"
				/>
			</Form.Item>
		</Form>
	);
};
export default App;`;

export const codeFloatQuill = `import { Form } from "antd";

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import {
	formats,
	modulesToolBar,
} from "./providers/reactQuillOptions";
Quill.register("modules/imageResize", ImageResize);

const App = () => {
	const [form] = Form.useForm();

	const onFinish = (values) => {
		console.log("onFinish", values);
	};

	return (
		<Form form={form} onFinish={onFinish}>
			<Form.Item name="quill">
                                <ReactQuill
                                    theme="snow"
                                    style={{ height: 200 }}
                                    modules={modulesToolBar}
                                    formats={formats}
                                    placeholder="Quill"
                                />
                        </Form.Item>
                        <br />
                        <br />
		</Form>
	);
};
export default App;`;
