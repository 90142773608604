import React from "react";
import { Card, Col, Row, Typography } from "antd";

import Highcharts from "highcharts";
// require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/boost")(Highcharts);

export default function PageDashboard() {
	return (
		<Card id="PageDashboard">
			<Row gutter={[12, 12]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={16}>
					<Typography.Title level={4}>
						This theme is for 5Pints usage/distribution. Any other usage of this
						is not allowed.
					</Typography.Title>
				</Col>
			</Row>
		</Card>
	);
}
