import { Card, Col, Divider, Progress, Row, Typography } from "antd";
import { red, green } from "@ant-design/colors";
import ButtonShowCode from "../Components/ButtonShowCode";
import { CodeBlock, dracula } from "react-code-blocks";
import {
	codeCircularProgressBar,
	codeProgressBar,
	codeProgressBarWithStep,
} from "./ComponentCodeBox";

export default function PageProgress() {
	return (
		<Card className="page-component-popups">
			<Row gutter={[12, 12]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					More on:{" "}
					<Typography.Link
						href="https://ant.design/components/progress/"
						target="new"
					>
						https://ant.design/components/progress/
					</Typography.Link>
				</Col>
				<Col xs={24} sm={24} md={24} lg={24} xl={12}>
					<Card>
						<>
							<Progress percent={30} />
							<Progress percent={50} status="active" />
							<Progress percent={70} status="exception" />
							<Progress percent={100} />
							<Progress percent={50} showInfo={false} />
						</>

						<Divider className="m-b-xs" orientation="left" plain>
							Progress bar
						</Divider>

						<div className="text-center">
							<ButtonShowCode id="divCodeProgressBar" />
						</div>

						<div id="divCodeProgressBar" className="hide">
							<Divider className="m-t-xs m-b-sm" />

							<CodeBlock
								text={codeProgressBar}
								language="jsx"
								showLineNumbers={false}
								theme={dracula}
								codeBlock
								className="component-pre"
								code
							/>
						</div>
					</Card>
				</Col>
				<Col xs={24} sm={24} md={24} lg={24} xl={12}>
					<Card>
						<>
							<Progress type="circle" percent={75} />
							<Progress type="circle" percent={70} status="exception" />
							<Progress type="circle" percent={100} />
						</>

						<Divider className="m-b-xs" orientation="left" plain>
							Circular Progress bar
						</Divider>

						<div className="text-center">
							<ButtonShowCode id="divCodeCircularProgressBar" />
						</div>

						<div id="divCodeCircularProgressBar" className="hide">
							<Divider className="m-t-xs m-b-sm" />

							<CodeBlock
								text={codeCircularProgressBar}
								language="jsx"
								showLineNumbers={false}
								theme={dracula}
								codeBlock
								className="component-pre"
								code
							/>
						</div>
					</Card>
				</Col>
				<Col xs={24} sm={24} md={24} lg={24} xl={12}>
					<Card>
						<>
							<Progress percent={50} steps={3} />
							<br />
							<Progress percent={30} steps={5} />
							<br />
							<Progress
								percent={100}
								steps={5}
								size="small"
								strokeColor={green[6]}
							/>
							<br />
							<Progress
								percent={60}
								steps={5}
								strokeColor={[green[6], green[6], red[5]]}
							/>
						</>

						<Divider className="m-b-xs" orientation="left" plain>
							Progress bar with steps
						</Divider>

						<div className="text-center">
							<ButtonShowCode id="divCodeProgressBarWithStep" />
						</div>

						<div id="divCodeProgressBarWithStep" className="hide">
							<Divider className="m-t-xs m-b-sm" />

							<CodeBlock
								text={codeProgressBarWithStep}
								language="jsx"
								showLineNumbers={false}
								theme={dracula}
								codeBlock
								className="component-pre"
								code
							/>
						</div>
					</Card>
				</Col>
			</Row>
		</Card>
	);
}
