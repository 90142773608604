export const codeAccordion = `import React from "react";
import { Collapse } from "antd";
const text = \`
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
\`;
const App = () => {
	const onChange = (key) => {
		console.log(key);
	};
	return (
		<Collapse
			className="main-1-collapse border-none"
			expandIcon={({ isActive }) =>
				isActive ? (
					<span
						className="ant-menu-submenu-arrow"
						style={{ color: "#FFF", transform: "rotate(270deg)" }}
					></span>
				) : (
					<span
						className="ant-menu-submenu-arrow"
						style={{ color: "#FFF", transform: "rotate(90deg)" }}
					></span>
				)
			}
			defaultActiveKey={["1"]}
			expandIconPosition="start"
			onChange={onChange}
			accordion
		>
			<Collapse.Panel header="This is panel header 1" key="1">
				<p>{text}</p>
			</Collapse.Panel>
			<Collapse.Panel header="This is panel header 2" key="2">
				<p>{text}</p>
			</Collapse.Panel>
			<Collapse.Panel header="This is panel header 3" key="3">
				<p>{text}</p>
			</Collapse.Panel>
		</Collapse>
	);
};
export default App;`;

export const codeCollapse = `import React from "react";
import { Collapse } from "antd";
const text = \`
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
\`;
const App = () => {
	const onChange = (key) => {
		console.log(key);
	};
	return (
		<Collapse
			className="main-1-collapse border-none"
			expandIcon={({ isActive }) =>
				isActive ? (
					<span
						className="ant-menu-submenu-arrow"
						style={{ color: "#FFF", transform: "rotate(270deg)" }}
					></span>
				) : (
					<span
						className="ant-menu-submenu-arrow"
						style={{ color: "#FFF", transform: "rotate(90deg)" }}
					></span>
				)
			}
			defaultActiveKey={["1"]}
			expandIconPosition="start"
			onChange={onChange}
		>
			<Collapse.Panel header="This is panel header 1" key="1">
				<p>{text}</p>
			</Collapse.Panel>
			<Collapse.Panel header="This is panel header 2" key="2">
				<p>{text}</p>
			</Collapse.Panel>
			<Collapse.Panel header="This is panel header 3" key="3">
				<p>{text}</p>
			</Collapse.Panel>
		</Collapse>
	);
};
export default App;`;
