export const codeUiEnv = `REACT_APP_NAME = "SHAMCEY THEME"
REACT_APP_DESCRIPTION = "SHAMCEY THEME"
REACT_APP_KEY="SHAMCEY-THEME-APP"
REACT_APP_VERSION="2.0.0"
REACT_APP_LOGO = '/logo.png'
REACT_APP_FULLWIDTH_LOGO = '/fullwidth_logo.png'
REACT_APP_SIDEMENU_LOGO = '/sidemenu_logo.png'

REACT_APP_API_URL="http://127.0.0.1:9001/"
REACT_APP_API_KEY="xlpUGMb0Y1GDOx53KVysuRGU9sMcQhb4Vqlmmpk6q4o6VkEpn2ImJnVvesn5"`;

export const codeAdminEnv = `APP_NAME="SHAMCEY THEME"
APP_ENV=local
APP_KEY=base64:sZQ6wnd/mIoAMeamQJMOM2WrTc052f3llFaikwZlsSY=
APP_DEBUG=true
APP_URL=http://localhost/

DB_CONNECTION=mysql
DB_HOST=127.0.0.1
DB_PORT=3306
DB_DATABASE=justin_shamcey_theme
DB_USERNAME=root
DB_PASSWORD=

# MAIL_DRIVER=smtp
# MAIL_HOST=mail.5ppsite.com
# MAIL_PORT=465
# MAIL_USERNAME=bfss@5ppsite.com
# MAIL_PASSWORD="fakepassword123!"
# MAIL_ENCRYPTION=ssl
# MAIL_FROM_NAME="$\{APP_NAME}"

MAIL_DRIVER=smtp
MAIL_HOST=smtp-relay.sendinblue.com
MAIL_PORT=587
MAIL_USERNAME=it@fivepints.com
MAIL_PASSWORD="37fBa4hdk0XVL1UI"
MAIL_ENCRYPTION=tls
MAIL_FROM_NAME="$\{APP_NAME}"

MIX_APP_NAME = "SHAMCEY THEME"
MIX_APP_DESCRIPTION = "SHAMCEY THEME"
MIX_APP_KEY="SHAMCEY-THEME-APP"
MIX_APP_FAVICON = "images/favicon.ico"
MIX_APP_LOGO = "images/logo.png"
MIX_APP_FULLWIDTH_LOGO = "images/fullwidth_logo.png"
MIX_APP_SIDEMENU_LOGO = "images/sidemenu_logo.png"

MIX_APP_DEFAULT_PROFILE = "images/default.png"

MIX_APP_STRIPE_API_KEY="sk_test_51KnNHNKZSUuelLLRWXzLtVN17fnBkhTYn9qZa7XxCKVxSLc6g35pEK1s97SDLQhCdvyzJAiGfWXoO6fA9pltFEUb00qJGDf6ne"
MIX_APP_STRIPE_API_ENV="TESTMODE"`;
