import { Card, Col, Collapse, Divider, Row, Typography } from "antd";
import React from "react";
import { CodeBlock, dracula } from "react-code-blocks";
import ButtonShowCode from "../Components/ButtonShowCode";
import { codeAccordion, codeCollapse } from "./ComponentCodeBox";

export default function PageAccordions() {
	return (
		<Card className="page-component-accordion">
			<Row gutter={[12, 12]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					More on:{" "}
					<Typography.Link
						href="https://ant.design/components/collapse/"
						target="new"
					>
						https://ant.design/components/collapse/
					</Typography.Link>
				</Col>
				<Col xs={24} sm={24} md={24} lg={24} xl={12}>
					<Card>
						<Collapse
							className="main-1-collapse border-none"
							expandIcon={({ isActive }) =>
								isActive ? (
									<span
										className="ant-menu-submenu-arrow"
										style={{ color: "#FFF", transform: "rotate(270deg)" }}
									></span>
								) : (
									<span
										className="ant-menu-submenu-arrow"
										style={{ color: "#FFF", transform: "rotate(90deg)" }}
									></span>
								)
							}
							defaultActiveKey={["1"]}
							expandIconPosition="start"
							accordion
						>
							<Collapse.Panel
								header="This is panel header 1"
								key="1"
								className="accordion bg-darkgray-form m-b-md border bgcolor-1 white"
							>
								A dog is a type of domesticated animal. Known for its loyalty
								and faithfulness, it can be found as a welcome guest in many
								households across the world.
							</Collapse.Panel>

							<Collapse.Panel
								header="This is panel header 2"
								key="2"
								className="accordion bg-darkgray-form m-b-md border bgcolor-1 white"
							>
								A dog is a type of domesticated animal. Known for its loyalty
								and faithfulness, it can be found as a welcome guest in many
								households across the world.
							</Collapse.Panel>

							<Collapse.Panel
								header="This is panel header 3"
								key="3"
								className="accordion bg-darkgray-form m-b-md border bgcolor-1 white"
							>
								A dog is a type of domesticated animal. Known for its loyalty
								and faithfulness, it can be found as a welcome guest in many
								households across the world.
							</Collapse.Panel>
						</Collapse>

						<Divider className="m-b-xs" orientation="left" plain>
							Accordion
						</Divider>

						<div className="text-center">
							<ButtonShowCode id="divCodeAccordion" />
						</div>

						<div id="divCodeAccordion" className="hide">
							<Divider className="m-t-xs m-b-sm" />

							<CodeBlock
								text={codeAccordion}
								language="jsx"
								showLineNumbers={false}
								theme={dracula}
								codeBlock
								className="component-pre"
								code
							/>
						</div>
					</Card>
				</Col>
				<Col xs={24} sm={24} md={24} lg={24} xl={12}>
					<Card>
						<Collapse
							className="main-1-collapse border-none"
							expandIcon={({ isActive }) =>
								isActive ? (
									<span
										className="ant-menu-submenu-arrow"
										style={{ color: "#FFF", transform: "rotate(270deg)" }}
									></span>
								) : (
									<span
										className="ant-menu-submenu-arrow"
										style={{ color: "#FFF", transform: "rotate(90deg)" }}
									></span>
								)
							}
							defaultActiveKey={["1"]}
							expandIconPosition="start"
						>
							<Collapse.Panel
								header="This is panel header 1"
								key="1"
								className="accordion bg-darkgray-form m-b-md border bgcolor-1 white"
							>
								A dog is a type of domesticated animal. Known for its loyalty
								and faithfulness, it can be found as a welcome guest in many
								households across the world.
							</Collapse.Panel>

							<Collapse.Panel
								header="This is panel header 2"
								key="2"
								className="accordion bg-darkgray-form m-b-md border bgcolor-1 white"
							>
								A dog is a type of domesticated animal. Known for its loyalty
								and faithfulness, it can be found as a welcome guest in many
								households across the world.
							</Collapse.Panel>

							<Collapse.Panel
								header="This is panel header 3"
								key="3"
								className="accordion bg-darkgray-form m-b-md border bgcolor-1 white"
							>
								A dog is a type of domesticated animal. Known for its loyalty
								and faithfulness, it can be found as a welcome guest in many
								households across the world.
							</Collapse.Panel>
						</Collapse>

						<Divider className="m-b-xs" orientation="left" plain>
							Collapse
						</Divider>

						<div className="text-center">
							<ButtonShowCode id="divCodeCollapse" />
						</div>

						<div id="divCodeCollapse" className="hide">
							<Divider className="m-t-xs m-b-sm" />

							<CodeBlock
								text={codeCollapse}
								language="jsx"
								showLineNumbers={false}
								theme={dracula}
								codeBlock
								className="component-pre"
								code
							/>
						</div>
					</Card>
				</Col>
				<Col xs={24} sm={24} md={24} lg={24} xl={12}></Col>
			</Row>
		</Card>
	);
}
