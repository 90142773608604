import { useState } from "react";
import { Button, Card, Col, Divider, Modal, Row, Typography } from "antd";
import ButtonShowCode from "../Components/ButtonShowCode";
import { CodeBlock, dracula } from "react-code-blocks";
import { codeModal } from "./ComponentCodeBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-light-svg-icons";

export default function PageModals() {
	const [toggleModal, setToggleModal] = useState(false);

	const handleOk = () => {
		setToggleModal(false);
	};
	const handleCancel = () => {
		setToggleModal(false);
	};

	return (
		<Card className="page-component-modal">
			<Row gutter={[12, 12]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					More on:{" "}
					<Typography.Link
						href="https://ant.design/components/modal/"
						target="new"
					>
						https://ant.design/components/modal/
					</Typography.Link>
				</Col>
				<Col xs={24} sm={24} md={24} lg={24} xl={12}>
					<Card>
						<Button
							className="btn-main-invert-outline b-r-none"
							icon={
								<FontAwesomeIcon
									icon={faArrowUpRightFromSquare}
									className="m-r-xs"
								/>
							}
							onClick={() => setToggleModal(true)}
							size="large"
						>
							Open Modal
						</Button>

						<Divider className="m-b-xs" orientation="left" plain>
							Basic
						</Divider>

						<div className="text-center">
							<ButtonShowCode id="divCodeModal" />
						</div>

						<div id="divCodeModal" className="hide">
							<Divider className="m-t-xs m-b-sm" />

							<CodeBlock
								text={codeModal}
								language="jsx"
								showLineNumbers={false}
								theme={dracula}
								codeBlock
								className="component-pre"
								code
							/>
						</div>
					</Card>
				</Col>
				<Col xs={24} sm={24} md={24} lg={24} xl={12}>
					{/* <Card></Card> */}
				</Col>
			</Row>

			<Modal
				title="Basic Modal"
				open={toggleModal}
				onOk={handleOk}
				onCancel={handleCancel}
			>
				<p>Some contents...</p>
				<p>Some contents...</p>
				<p>Some contents...</p>
			</Modal>
		</Card>
	);
}
