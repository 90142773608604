import { useEffect, useState } from "react";
import {
	Button,
	Card,
	Col,
	Divider,
	notification,
	Popconfirm,
	Row,
	Table,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	TableGlobalSearch,
	TablePageSize,
	TablePagination,
	TableShowingEntries,
} from "../../../../providers/CustomTableFilter";
import { GET } from "../../../../providers/useAxiosQuery";
import moment from "moment";
import { faPencil, faTrash } from "@fortawesome/pro-regular-svg-icons";
import ButtonShowCode from "../Components/ButtonShowCode";
import { CodeBlock, dracula } from "react-code-blocks";
import { codeTable } from "./ComponentCodeBox";

export default function PageTable() {
	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		sort_field: "id",
		sort_order: "desc",
		status: "Active",
	});

	const { data: dataSource, refetch: refetchSource } = GET(
		`api/v1/users?${new URLSearchParams(tableFilter)}`,
		"users_active_list"
	);

	const onChangeTable = (pagination, filters, sorter) => {
		setTableFilter({
			...tableFilter,
			sort_field: sorter.columnKey,
			sort_order: sorter.order ? sorter.order.replace("end", "") : null,
			page: 1,
			page_size: "50",
			loading: true,
		});
	};

	useEffect(() => {
		if (dataSource) {
			refetchSource();
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	return (
		<Card className="page-component-table">
			<Card>
				<Row gutter={[12, 12]}>
					<Col xs={24} sm={24} md={24}>
						<div className="ant-space-flex-space-between table-size-table-search">
							<div>
								<TablePageSize
									tableFilter={tableFilter}
									setTableFilter={setTableFilter}
								/>
							</div>
							<div>
								<TableGlobalSearch
									tableFilter={tableFilter}
									setTableFilter={setTableFilter}
								/>
							</div>
						</div>
					</Col>
					<Col xs={24} sm={24} md={24}>
						<Table
							className="ant-table-default ant-table-striped"
							dataSource={dataSource && dataSource.data.data}
							rowKey={(record) => record.id}
							pagination={false}
							bordered={false}
							onChange={onChangeTable}
							scroll={{ x: "max-content" }}
						>
							<Table.Column
								title="Start Date"
								key="created_at"
								dataIndex="created_at"
								render={(text, _) =>
									text ? moment(text).format("MM/DD/YYYY") : ""
								}
								defaultSortOrder="descend"
								sorter={true}
							/>
							<Table.Column
								title="Last Name"
								key="lastname"
								dataIndex="lastname"
								sorter={true}
								render={(text, record) => {
									return (
										<Button type="link" className="color-1">
											{text}
										</Button>
									);
								}}
							/>
							<Table.Column
								title="First Name"
								key="firstname"
								dataIndex="firstname"
								sorter={true}
								render={(text, record) => {
									return (
										<Button type="link" className="color-1">
											{text}
										</Button>
									);
								}}
							/>
							<Table.Column
								title="Type"
								key="role"
								dataIndex="role"
								sorter={true}
							/>
							<Table.Column
								title="Organization"
								key="organization"
								dataIndex="organization"
								sorter={true}
							/>
							<Table.Column
								title="Action"
								key="action"
								dataIndex="action"
								align="center"
								render={(text, record) => {
									return (
										<>
											<Button type="link" className="color-1">
												<FontAwesomeIcon icon={faPencil} />
											</Button>
											<Popconfirm
												title="Are you sure to deactivate this data?"
												onConfirm={() => {
													notification.error({
														message: "User Deactivate",
														description: "Data not deactivate",
													});
												}}
												onCancel={() => {
													notification.error({
														message: "User Deactivate",
														description: "Data not deactivate",
													});
												}}
												okText="Yes"
												cancelText="No"
											>
												<Button type="link" className="color-6">
													<FontAwesomeIcon icon={faTrash} />
												</Button>
											</Popconfirm>
										</>
									);
								}}
							/>
						</Table>
					</Col>
					<Col xs={24} sm={24} md={24}>
						<div className="ant-space-flex-space-between table-entries-table-pagination">
							<TableShowingEntries />
							<TablePagination
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
								setPaginationTotal={dataSource?.data.total}
								showLessItems={true}
								showSizeChanger={false}
							/>
						</div>
					</Col>
				</Row>

				<Divider className="m-b-xs" orientation="left" plain>
					Table
				</Divider>

				<div className="text-center">
					<ButtonShowCode id="divCodeTable" />
				</div>

				<div id="divCodeTable" className="hide">
					<Divider className="m-t-xs m-b-sm" />

					<CodeBlock
						text={codeTable}
						language="jsx"
						showLineNumbers={false}
						theme={dracula}
						codeBlock
						className="component-pre"
						code
					/>
				</div>
			</Card>
		</Card>
	);
}
