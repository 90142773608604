import { Alert, Card, Col, Divider, Row, Space, Typography } from "antd";
import React from "react";
import { CodeBlock, dracula } from "react-code-blocks";
import ButtonShowCode from "../Components/ButtonShowCode";
import { codeAlertBasic, codeAlertWithIcon } from "./ComponentCodeBox";

export default function PageAlerts() {
	return (
		<Card className="page-component-alert">
			<Row gutter={[12, 12]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					More on:{" "}
					<Typography.Link
						href="https://ant.design/components/alert/"
						target="new"
					>
						https://ant.design/components/alert/
					</Typography.Link>
				</Col>
				<Col xs={24} sm={24} md={24} lg={24} xl={12}>
					<Card>
						<Space direction="vertical" className="w-100">
							<Alert message="Success Text" type="success" />
							<Alert message="Info Text" type="info" />
							<Alert message="Warning Text" type="warning" />
							<Alert message="Error Text" type="error" />
						</Space>

						<Divider className="m-b-xs" orientation="left" plain>
							More types
						</Divider>

						<div className="text-center">
							<ButtonShowCode id="divCodeAlertBasic" />
						</div>

						<div id="divCodeAlertBasic" className="hide">
							<Divider className="m-t-xs m-b-sm" />

							<CodeBlock
								text={codeAlertBasic}
								language="jsx"
								showLineNumbers={false}
								theme={dracula}
								codeBlock
								className="component-pre"
								code
							/>
						</div>
					</Card>
				</Col>
				<Col xs={24} sm={24} md={24} lg={24} xl={12}>
					<Card>
						<Space direction="vertical" className="w-100">
							<Alert message="Success Tips" type="success" showIcon />
							<Alert message="Informational Notes" type="info" showIcon />
							<Alert message="Warning" type="warning" showIcon closable />
							<Alert message="Error" type="error" showIcon />
							<Alert
								message="Success Tips"
								description="Detailed description and advice about successful copywriting."
								type="success"
								showIcon
							/>
							<Alert
								message="Informational Notes"
								description="Additional description and information about copywriting."
								type="info"
								showIcon
							/>
							<Alert
								message="Warning"
								description="This is a warning notice about copywriting."
								type="warning"
								showIcon
								closable
							/>
							<Alert
								message="Error"
								description="This is an error message about copywriting."
								type="error"
								showIcon
							/>
						</Space>

						<Divider className="m-b-xs" orientation="left" plain>
							With Icon
						</Divider>

						<div className="text-center">
							<ButtonShowCode id="divCodeAlertWithIcon" />
						</div>

						<div id="divCodeAlertWithIcon" className="hide">
							<Divider className="m-t-xs m-b-sm" />

							<CodeBlock
								text={codeAlertWithIcon}
								language="jsx"
								showLineNumbers={false}
								theme={dracula}
								codeBlock
								className="component-pre"
								code
							/>
						</div>
					</Card>
				</Col>
				<Col xs={24} sm={24} md={24} lg={24} xl={12}></Col>
			</Row>
		</Card>
	);
}
