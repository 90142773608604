import { useState } from "react";
import { faCode, faCodeSimple } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";

import $ from "jquery";

export default function ButtonShowCode({ id }) {
	const [showHide, setShowHide] = useState(false);
	return (
		<Button
			size="small"
			icon={<FontAwesomeIcon icon={showHide ? faCode : faCodeSimple} />}
			onClick={() => {
				setShowHide(!showHide ?? true);
				if ($("#" + id).hasClass("hide")) {
					$("#" + id).removeClass("hide");
				} else {
					$("#" + id).addClass("hide");
				}
			}}
			type="text"
			className="color-1"
		/>
	);
}
