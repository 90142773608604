import { Card } from "antd";
import { CodeBlock, dracula } from "react-code-blocks";
import { codeSidemenu } from "./ComponentCodeBox";

export default function PageSidemenu() {
	return (
		<Card>
			<CodeBlock
				text={codeSidemenu}
				language="jsx"
				showLineNumbers={false}
				theme={dracula}
				codeBlock
				className="component-pre"
				code
			/>
		</Card>
	);
}
