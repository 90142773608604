import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, Col, Row, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faArrowsSpin,
	faChartMixed,
	faGrid2,
	faHeadphonesSimple,
	faInputText,
	faLinkSimple,
	faRoute,
	faSidebar,
	faTable,
	faTableLayout,
	faTabletButton,
} from "@fortawesome/pro-light-svg-icons";

import $ from "jquery";

export default function PageQuickLink() {
	const history = useHistory();

	const [hasCollapse, setHasCollapse] = useState(
		$(".private-layout > .ant-layout-content").hasClass(
			"ant-layout-has-collapse"
		)
			? true
			: false
	);

	useEffect(() => {
		$("#btn_sidemenu_collapse_unfold").on("click", function () {
			setHasCollapse(false);
			// console.log("btn_sidemenu_collapse_unfold");
		});
		$("#btn_sidemenu_collapse_fold").on("click", function () {
			setHasCollapse(true);
			// console.log("btn_sidemenu_collapse_fold");
		});

		return () => {};
	}, []);

	return (
		<Card>
			<Row gutter={[12, 12]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={16}>
					<Card
						// title="QUICK LINKS"
						className="card-transparent-head border-bottom-none no-side-border p-none card-quick-links"
						bodyStyle={{ padding: "0px" }}
					>
						<Row gutter={[12, 12]} className="ant-row-quick-link">
							<Col xs={12} sm={12} md={12} lg={hasCollapse ? 6 : 12} xl={6}>
								<Button
									type="link"
									className="ant-btn-quick-link"
									onClick={() => history.push("/layout")}
								>
									<FontAwesomeIcon icon={faTableLayout} />
									<Typography.Text>Layout</Typography.Text>
								</Button>
							</Col>

							<Col xs={12} sm={12} md={12} lg={hasCollapse ? 6 : 12} xl={6}>
								<Button
									type="link"
									className="ant-btn-quick-link"
									onClick={() => history.push("/sidemenu")}
								>
									<FontAwesomeIcon icon={faSidebar} />
									<Typography.Text>Side Menu</Typography.Text>
								</Button>
							</Col>

							<Col xs={12} sm={12} md={12} lg={hasCollapse ? 6 : 12} xl={6}>
								<Button
									type="link"
									className="ant-btn-quick-link"
									onClick={() => history.push("/header")}
								>
									<FontAwesomeIcon icon={faHeadphonesSimple} />
									<Typography.Text>header</Typography.Text>
								</Button>
							</Col>

							<Col xs={12} sm={12} md={12} lg={hasCollapse ? 6 : 12} xl={6}>
								<Button
									type="link"
									className="ant-btn-quick-link"
									onClick={() => history.push("/grid")}
								>
									<FontAwesomeIcon icon={faGrid2} />
									<Typography.Text>Grid</Typography.Text>
								</Button>
							</Col>

							<Col xs={12} sm={12} md={12} lg={hasCollapse ? 6 : 12} xl={6}>
								<Button
									type="link"
									className="ant-btn-quick-link"
									onClick={() => history.push("/buttons")}
								>
									<FontAwesomeIcon icon={faTabletButton} />
									<Typography.Text>Buttons</Typography.Text>
								</Button>
							</Col>

							<Col xs={12} sm={12} md={12} lg={hasCollapse ? 6 : 12} xl={6}>
								<Button
									type="link"
									className="ant-btn-quick-link"
									onClick={() => history.push("/inputs")}
								>
									<FontAwesomeIcon icon={faInputText} />
									<Typography.Text>Inputs</Typography.Text>
								</Button>
							</Col>

							<Col xs={12} sm={12} md={12} lg={hasCollapse ? 6 : 12} xl={6}>
								<Button
									type="link"
									className="ant-btn-quick-link"
									onClick={() => history.push("/table")}
								>
									<FontAwesomeIcon icon={faTable} />
									<Typography.Text>Table</Typography.Text>
								</Button>
							</Col>

							<Col xs={12} sm={12} md={12} lg={hasCollapse ? 6 : 12} xl={6}>
								<Button
									type="link"
									className="ant-btn-quick-link"
									onClick={() => history.push("/charts")}
								>
									<FontAwesomeIcon icon={faChartMixed} />
									<Typography.Text>Charts</Typography.Text>
								</Button>
							</Col>

							<Col xs={12} sm={12} md={12} lg={hasCollapse ? 6 : 12} xl={6}>
								<Button
									type="link"
									className="ant-btn-quick-link"
									onClick={() => history.push("/routes")}
								>
									<FontAwesomeIcon icon={faRoute} />
									<Typography.Text>Routes</Typography.Text>
								</Button>
							</Col>

							<Col xs={12} sm={12} md={12} lg={hasCollapse ? 6 : 12} xl={6}>
								<Button
									type="link"
									className="ant-btn-quick-link"
									onClick={() => history.push("/quick-links")}
								>
									<FontAwesomeIcon icon={faLinkSimple} />
									<Typography.Text>Quick Links</Typography.Text>
								</Button>
							</Col>

							<Col xs={12} sm={12} md={12} lg={hasCollapse ? 6 : 12} xl={6}>
								<Button
									type="link"
									className="ant-btn-quick-link"
									onClick={() => history.push("/changes")}
								>
									<FontAwesomeIcon icon={faArrowsSpin} />
									<Typography.Text>Changes</Typography.Text>
								</Button>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		</Card>
	);
}
