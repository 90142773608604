export const codeBasicLine = `import { useEffect } from "react";
import highchartsSetOptions from "../../../../providers/highchartsSetOptions";
import Highcharts from "highcharts";
// require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/boost")(Highcharts);

const App = () => {
  highchartsSetOptions(Highcharts);

	useEffect(() => {
		Highcharts.chart("div_basic_line", {
			credits: false,
			title: {
				text: "U.S Solar Employment Growth by Job Category, 2010-2020",
			},

			subtitle: {
				text: 'Source: <a href="https://irecusa.org/programs/solar-jobs-census/" target="_blank">IREC</a>',
			},

			yAxis: {
				title: {
					text: "Number of Employees",
				},
			},

			xAxis: {
				accessibility: {
					rangeDescription: "Range: 2010 to 2020",
				},
			},

			legend: {
				layout: "vertical",
				align: "right",
				verticalAlign: "middle",
			},

			plotOptions: {
				series: {
					label: {
						connectorAllowed: false,
					},
					pointStart: 2010,
				},
			},

			series: [
				{
					name: "Installation & Developers",
					data: [
						43934, 48656, 65165, 81827, 112143, 142383, 171533, 165174, 155157,
						161454, 154610,
					],
				},
				{
					name: "Manufacturing",
					data: [
						24916, 37941, 29742, 29851, 32490, 30282, 38121, 36885, 33726,
						34243, 31050,
					],
				},
				{
					name: "Sales & Distribution",
					data: [
						11744, 30000, 16005, 19771, 20185, 24377, 32147, 30912, 29243,
						29213, 25663,
					],
				},
				{
					name: "Operations & Maintenance",
					data: [
						null,
						null,
						null,
						null,
						null,
						null,
						null,
						null,
						11164,
						11218,
						10077,
					],
				},
				{
					name: "Other",
					data: [
						21908, 5548, 8105, 11248, 8989, 11816, 18274, 17300, 13053, 11906,
						10073,
					],
				},
			],

			responsive: {
				rules: [
					{
						condition: {
							maxWidth: 500,
						},
						chartOptions: {
							legend: {
								layout: "horizontal",
								align: "center",
								verticalAlign: "bottom",
							},
						},
					},
				],
			},
		});

		return () => {};
	}, []);

	return <div id="div_basic_line" />;
};
export default App;`;

export const codeBasicArea = `import { useEffect } from "react";
import highchartsSetOptions from "../../../../providers/highchartsSetOptions";
import Highcharts from "highcharts";
// require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/boost")(Highcharts);

const App = () => {
  highchartsSetOptions(Highcharts);

	useEffect(() => {
		Highcharts.chart('div_basic_area', {
      chart: {
          type: 'area'
      },
      accessibility: {
          description: 'Image description: An area chart compares the nuclear stockpiles of the USA and the USSR/Russia between 1945 and 2017. The number of nuclear weapons is plotted on the Y-axis and the years on the X-axis. The chart is interactive, and the year-on-year stockpile levels can be traced for each country. The US has a stockpile of 6 nuclear weapons at the dawn of the nuclear age in 1945. This number has gradually increased to 369 by 1950 when the USSR enters the arms race with 6 weapons. At this point, the US starts to rapidly build its stockpile culminating in 32,040 warheads by 1966 compared to the USSR’s 7,089. From this peak in 1966, the US stockpile gradually decreases as the USSR’s stockpile expands. By 1978 the USSR has closed the nuclear gap at 25,393. The USSR stockpile continues to grow until it reaches a peak of 45,000 in 1986 compared to the US arsenal of 24,401. From 1986, the nuclear stockpiles of both countries start to fall. By 2000, the numbers have fallen to 10,577 and 21,000 for the US and Russia, respectively. The decreases continue until 2017 at which point the US holds 4,018 weapons compared to Russia’s 4,500.'
      },
      title: {
          text: 'US and USSR nuclear stockpiles'
      },
      subtitle: {
          text: 'Source: <a href="https://fas.org/issues/nuclear-weapons/status-world-nuclear-forces/" ' +
              'target="_blank">FAS</a>'
      },
      xAxis: {
          allowDecimals: false,
          labels: {
              formatter: function () {
                  return this.value; // clean, unformatted number for year
              }
          },
          accessibility: {
              rangeDescription: 'Range: 1940 to 2017.'
          }
      },
      yAxis: {
          title: {
              text: 'Nuclear weapon states'
          },
          labels: {
              formatter: function () {
                  return this.value / 1000 + 'k';
              }
          }
      },
      tooltip: {
          pointFormat: '{series.name} had stockpiled <b>{point.y:,.0f}</b><br/>warheads in {point.x}'
      },
      plotOptions: {
          area: {
              pointStart: 1940,
              marker: {
                  enabled: false,
                  symbol: 'circle',
                  radius: 2,
                  states: {
                      hover: {
                          enabled: true
                      }
                  }
              }
          }
      },
      series: [{
          name: 'USA',
          data: [
              null, null, null, null, null, 2, 9, 13, 50, 170, 299, 438, 841,
              1169, 1703, 2422, 3692, 5543, 7345, 12298, 18638, 22229, 25540,
              28133, 29463, 31139, 31175, 31255, 29561, 27552, 26008, 25830,
              26516, 27835, 28537, 27519, 25914, 25542, 24418, 24138, 24104,
              23208, 22886, 23305, 23459, 23368, 23317, 23575, 23205, 22217,
              21392, 19008, 13708, 11511, 10979, 10904, 11011, 10903, 10732,
              10685, 10577, 10526, 10457, 10027, 8570, 8360, 7853, 5709, 5273,
              5113, 5066, 4897, 4881, 4804, 4717, 4571, 4018, 3822, 3785, 3805,
              3750, 3708, 3708
          ]
      }, {
          name: 'USSR/Russia',
          data: [null, null, null, null, null, null, null, null, null,
              1, 5, 25, 50, 120, 150, 200, 426, 660, 863, 1048, 1627, 2492,
              3346, 4259, 5242, 6144, 7091, 8400, 9490, 10671, 11736, 13279,
              14600, 15878, 17286, 19235, 22165, 24281, 26169, 28258, 30665,
              32146, 33486, 35130, 36825, 38582, 40159, 38107, 36538, 35078,
              32980, 29154, 26734, 24403, 21339, 18179, 15942, 15442, 14368,
              13188, 12188, 11152, 10114, 9076, 8038, 7000, 6643, 6286, 5929,
              5527, 5215, 4858, 4750, 4650, 4600, 4500, 4490, 4300, 4350, 4330,
              4310, 4495, 4477
          ]
      }]
  });

		return () => {};
	}, []);

	return <div id="div_basic_area" />;
};
export default App;`;

export const codeBasicColumn = `import { useEffect } from "react";
import highchartsSetOptions from "../../../../providers/highchartsSetOptions";
import Highcharts from "highcharts";
// require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/boost")(Highcharts);

const App = () => {
  highchartsSetOptions(Highcharts);

	useEffect(() => {
		Highcharts.chart('div_basic_column', {
      chart: {
          type: 'column'
      },
      title: {
          text: 'Emissions to air in Norway'
      },
      subtitle: {
          text: 'Source: ' +
              '<a href="https://www.ssb.no/en/statbank/table/08940/" ' +
              'target="_blank">SSB</a>'
      },
      xAxis: {
          categories: [
              '2010',
              '2011',
              '2012',
              '2013',
              '2014',
              '2015',
              '2016',
              '2017',
              '2018',
              '2019',
              '2020',
              '2021'
          ],
          crosshair: true
      },
      yAxis: {
          title: {
              useHTML: true,
              text: 'Million tonnes CO<sub>2</sub>-equivalents'
          }
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          }
      },
      series: [{
          name: 'Oil and gas extraction',
          data: [13.93, 13.63, 13.73, 13.67, 14.37, 14.89, 14.56,
              14.32, 14.13, 13.93, 13.21, 12.16]
  
      }, {
          name: 'Manufacturing industries and mining',
          data: [12.24, 12.24, 11.95, 12.02, 11.65, 11.96, 11.59,
              11.94, 11.96, 11.59, 11.42, 11.76]
  
      }, {
          name: 'Road traffic',
          data: [10.00, 9.93, 9.97, 10.01, 10.23, 10.26, 10.00,
              9.12, 9.36, 8.72, 8.38, 8.69]
  
      }, {
          name: 'Agriculture',
          data: [4.35, 4.32, 4.34, 4.39, 4.46, 4.52, 4.58, 4.55,
              4.53, 4.51, 4.49, 4.57]
  
      }]
  });

		return () => {};
	}, []);

	return <div id="div_basic_column" />;
};
export default App;`;

export const codeBasicBar = `import { useEffect } from "react";
import highchartsSetOptions from "../../../../providers/highchartsSetOptions";
import Highcharts from "highcharts";
// require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/boost")(Highcharts);

const App = () => {
  highchartsSetOptions(Highcharts);

	useEffect(() => {
		Highcharts.chart('div_basic_bar', {
      chart: {
          type: 'bar'
      },
      title: {
          text: 'Historic World Population by Region'
      },
      subtitle: {
          text: 'Source: <a ' +
              'href="https://en.wikipedia.org/wiki/List_of_continents_and_continental_subregions_by_population"' +
              'target="_blank">Wikipedia.org</a>'
      },
      xAxis: {
          categories: ['Africa', 'America', 'Asia', 'Europe', 'Oceania'],
          title: {
              text: null
          }
      },
      yAxis: {
          min: 0,
          title: {
              text: 'Population (millions)',
              align: 'high'
          },
          labels: {
              overflow: 'justify'
          }
      },
      tooltip: {
          valueSuffix: ' millions'
      },
      plotOptions: {
          bar: {
              dataLabels: {
                  enabled: true
              }
          }
      },
      legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'top',
          x: -40,
          y: 80,
          floating: true,
          borderWidth: 1,
          backgroundColor:
              Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
          shadow: true
      },
      credits: {
          enabled: false
      },
      series: [{
          name: 'Year 1990',
          data: [631, 727, 3202, 721, 26]
      }, {
          name: 'Year 2000',
          data: [814, 841, 3714, 726, 31]
      }, {
          name: 'Year 2010',
          data: [1044, 944, 4170, 735, 40]
      }, {
          name: 'Year 2018',
          data: [1276, 1007, 4561, 746, 42]
      }]
  });
  

		return () => {};
	}, []);

	return <div id="div_basic_bar" />;
};
export default App;`;

export const codeColumnWithDrilldown = `import { useEffect } from "react";
import highchartsSetOptions from "../../../../providers/highchartsSetOptions";
import Highcharts from "highcharts";
// require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/boost")(Highcharts);
require("highcharts/modules/drilldown")(Highcharts);

const App = () => {
  highchartsSetOptions(Highcharts);

	useEffect(() => {
		// Data retrieved from https://gs.statcounter.com/browser-market-share#monthly-202201-202201-bar

// Create the chart
Highcharts.chart('div_column_with_drilldown', {
    chart: {
        type: 'column'
    },
    title: {
        align: 'left',
        text: 'Browser market shares. January, 2022'
    },
    subtitle: {
        align: 'left',
        text: 'Click the columns to view versions. Source: <a href="http://statcounter.com" target="_blank">statcounter.com</a>'
    },
    accessibility: {
        announceNewData: {
            enabled: true
        }
    },
    xAxis: {
        type: 'category'
    },
    yAxis: {
        title: {
            text: 'Total percent market share'
        }

    },
    legend: {
        enabled: false
    },
    plotOptions: {
        series: {
            borderWidth: 0,
            dataLabels: {
                enabled: true,
                format: '{point.y:.1f}%'
            }
        }
    },

    tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
    },

    series: [
        {
            name: "Browsers",
            colorByPoint: true,
            data: [
                {
                    name: "Chrome",
                    y: 63.06,
                    drilldown: "Chrome"
                },
                {
                    name: "Safari",
                    y: 19.84,
                    drilldown: "Safari"
                },
                {
                    name: "Firefox",
                    y: 4.18,
                    drilldown: "Firefox"
                },
                {
                    name: "Edge",
                    y: 4.12,
                    drilldown: "Edge"
                },
                {
                    name: "Opera",
                    y: 2.33,
                    drilldown: "Opera"
                },
                {
                    name: "Internet Explorer",
                    y: 0.45,
                    drilldown: "Internet Explorer"
                },
                {
                    name: "Other",
                    y: 1.582,
                    drilldown: null
                }
            ]
        }
    ],
    drilldown: {
        breadcrumbs: {
            position: {
                align: 'right'
            }
        },
        series: [
            {
                name: "Chrome",
                id: "Chrome",
                data: [
                    [
                        "v65.0",
                        0.1
                    ],
                    [
                        "v64.0",
                        1.3
                    ],
                    [
                        "v63.0",
                        53.02
                    ],
                    [
                        "v62.0",
                        1.4
                    ],
                    [
                        "v61.0",
                        0.88
                    ],
                    [
                        "v60.0",
                        0.56
                    ],
                    [
                        "v59.0",
                        0.45
                    ],
                    [
                        "v58.0",
                        0.49
                    ],
                    [
                        "v57.0",
                        0.32
                    ],
                    [
                        "v56.0",
                        0.29
                    ],
                    [
                        "v55.0",
                        0.79
                    ],
                    [
                        "v54.0",
                        0.18
                    ],
                    [
                        "v51.0",
                        0.13
                    ],
                    [
                        "v49.0",
                        2.16
                    ],
                    [
                        "v48.0",
                        0.13
                    ],
                    [
                        "v47.0",
                        0.11
                    ],
                    [
                        "v43.0",
                        0.17
                    ],
                    [
                        "v29.0",
                        0.26
                    ]
                ]
            },
            {
                name: "Firefox",
                id: "Firefox",
                data: [
                    [
                        "v58.0",
                        1.02
                    ],
                    [
                        "v57.0",
                        7.36
                    ],
                    [
                        "v56.0",
                        0.35
                    ],
                    [
                        "v55.0",
                        0.11
                    ],
                    [
                        "v54.0",
                        0.1
                    ],
                    [
                        "v52.0",
                        0.95
                    ],
                    [
                        "v51.0",
                        0.15
                    ],
                    [
                        "v50.0",
                        0.1
                    ],
                    [
                        "v48.0",
                        0.31
                    ],
                    [
                        "v47.0",
                        0.12
                    ]
                ]
            },
            {
                name: "Internet Explorer",
                id: "Internet Explorer",
                data: [
                    [
                        "v11.0",
                        6.2
                    ],
                    [
                        "v10.0",
                        0.29
                    ],
                    [
                        "v9.0",
                        0.27
                    ],
                    [
                        "v8.0",
                        0.47
                    ]
                ]
            },
            {
                name: "Safari",
                id: "Safari",
                data: [
                    [
                        "v11.0",
                        3.39
                    ],
                    [
                        "v10.1",
                        0.96
                    ],
                    [
                        "v10.0",
                        0.36
                    ],
                    [
                        "v9.1",
                        0.54
                    ],
                    [
                        "v9.0",
                        0.13
                    ],
                    [
                        "v5.1",
                        0.2
                    ]
                ]
            },
            {
                name: "Edge",
                id: "Edge",
                data: [
                    [
                        "v16",
                        2.6
                    ],
                    [
                        "v15",
                        0.92
                    ],
                    [
                        "v14",
                        0.4
                    ],
                    [
                        "v13",
                        0.1
                    ]
                ]
            },
            {
                name: "Opera",
                id: "Opera",
                data: [
                    [
                        "v50.0",
                        0.96
                    ],
                    [
                        "v49.0",
                        0.82
                    ],
                    [
                        "v12.1",
                        0.14
                    ]
                ]
            }
        ]
    }
});

  

		return () => {};
	}, []);

	return <div id="div_column_with_drilldown" />;
};
export default App;`;

export const codePieChart = `import { useEffect } from "react";
import highchartsSetOptions from "../../../../providers/highchartsSetOptions";
import Highcharts from "highcharts";
// require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/boost")(Highcharts);

const App = () => {
  highchartsSetOptions(Highcharts);

	useEffect(() => {
		// Data retrieved from https://netmarketshare.com
Highcharts.chart('div_pie_chart', {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
    },
    title: {
        text: 'Browser market shares in May, 2020'
    },
    tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
        }
    },
    series: [{
        name: 'Brands',
        colorByPoint: true,
        data: [{
            name: 'Chrome',
            y: 70.67,
            sliced: true,
            selected: true
        }, {
            name: 'Edge',
            y: 14.77
        },  {
            name: 'Firefox',
            y: 4.86
        }, {
            name: 'Safari',
            y: 2.63
        }, {
            name: 'Internet Explorer',
            y: 1.53
        },  {
            name: 'Opera',
            y: 1.40
        }, {
            name: 'Sogou Explorer',
            y: 0.84
        }, {
            name: 'QQ',
            y: 0.51
        }, {
            name: 'Other',
            y: 2.6
        }]
    }]
});


		return () => {};
	}, []);

	return <div id="div_pie_chart" />;
};
export default App;`;
