import {
	faArrowsSpin,
	faHome,
	faMicrochip,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const adminSideMenu = [
	{
		title: "Dashboard",
		path: "/dashboard",
		icon: <FontAwesomeIcon icon={faHome} />,
	},
	{
		title: "Components",
		path: "/component",
		icon: <FontAwesomeIcon icon={faArrowsSpin} />,
		children: [
			{
				title: "Getting Started",
				path: "/component/getting-started",
			},
			{
				title: "Layout",
				path: "/component/layout",
			},
			{
				title: "Side Menu",
				path: "/component/sidemenu",
			},
			{
				title: "Header",
				path: "/component/header",
			},
			{
				title: "Accordion",
				path: "/component/accordions",
			},
			{
				title: "Alerts",
				path: "/component/alerts",
			},
			{
				title: "Buttons",
				path: "/component/buttons",
			},
			{
				title: "Cards",
				path: "/component/cards",
			},
			{
				title: "Grid",
				path: "/component/grids",
			},
			{
				title: "Inputs",
				path: "/component/inputs",
			},
			{
				title: "Modals",
				path: "/component/modals",
			},
			{
				title: "Popups",
				path: "/component/popups",
			},
			{
				title: "Progress",
				path: "/component/progress",
			},
			{
				title: "Table",
				path: "/component/tables",
			},
			{
				title: "Typography",
				path: "/component/typography",
			},
			{
				title: "Charts",
				path: "/component/charts",
			},
			{
				title: "Routes",
				path: "/component/routes",
			},
			{
				title: "Quick Links",
				path: "/component/quick-links",
			},
		],
	},
	{
		title: "Changes",
		path: "/changes",
		icon: <FontAwesomeIcon icon={faMicrochip} />,
	},
];

export default adminSideMenu;
