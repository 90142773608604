import { useState } from "react";
import {
	Card,
	Col,
	Divider,
	Radio,
	Row,
	Space,
	Switch,
	Typography,
} from "antd";
import {
	CheckOutlined,
	HighlightOutlined,
	SmileFilled,
	SmileOutlined,
} from "@ant-design/icons";
import ButtonShowCode from "../Components/ButtonShowCode";
import { CodeBlock, dracula } from "react-code-blocks";
import {
	codeEllipsis,
	codeInteractive,
	codeTextAndLink,
	codeTypography,
} from "./ComponentCodeBox";
import { useMemo } from "react";

const { Paragraph } = Typography;

export default function PageTypography() {
	const [editableStr, setEditableStr] = useState("This is an editable text.");
	const [editableStrWithSuffix, setEditableStrWithSuffix] = useState(
		"This is a loooooooooooooooooooooooooooooooong editable text with suffix."
	);
	const [editableStrWithSuffixStartPart, editableStrWithSuffixSuffixPart] =
		useMemo(
			() => [
				editableStrWithSuffix.slice(0, -12),
				editableStrWithSuffix.slice(-12),
			],
			[editableStrWithSuffix]
		);
	const [customIconStr, setCustomIconStr] = useState(
		"Custom Edit icon and replace tooltip text."
	);
	const [clickTriggerStr, setClickTriggerStr] = useState(
		"Text or icon as trigger - click to start editing."
	);
	const [chooseTrigger, setChooseTrigger] = useState(["icon"]);
	const [customEnterIconStr, setCustomEnterIconStr] = useState(
		"Editable text with a custom enter icon in edit field."
	);
	const [noEnterIconStr, setNoEnterIconStr] = useState(
		"Editable text with no enter icon in edit field."
	);
	const [hideTooltipStr, setHideTooltipStr] = useState("Hide Edit tooltip.");
	const [lengthLimitedStr, setLengthLimitedStr] = useState(
		"This is an editable text with limited length."
	);
	const radioToState = (input) => {
		switch (input) {
			case "text":
				return ["text"];
			case "both":
				return ["icon", "text"];
			case "icon":
			default:
				return ["icon"];
		}
	};
	const stateToRadio = () => {
		if (chooseTrigger.indexOf("text") !== -1) {
			return chooseTrigger.indexOf("icon") !== -1 ? "both" : "text";
		}
		return "icon";
	};

	const [ellipsis, setEllipsis] = useState(true);

	return (
		<Card className="page-component-popups">
			<Row gutter={[12, 12]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					More on:{" "}
					<Typography.Link
						href="https://ant.design/components/tooltip/"
						target="new"
					>
						https://ant.design/components/tooltip/
					</Typography.Link>
				</Col>
				<Col xs={24} sm={24} md={24} lg={24} xl={12}>
					<Card>
						<>
							<Typography.Title>h1. Ant Design</Typography.Title>
							<Typography.Title level={2}>h2. Ant Design</Typography.Title>
							<Typography.Title level={3}>h3. Ant Design</Typography.Title>
							<Typography.Title level={4}>h4. Ant Design</Typography.Title>
							<Typography.Title level={5}>h5. Ant Design</Typography.Title>
						</>

						<Divider className="m-b-xs" orientation="left" plain>
							Title Component
						</Divider>

						<div className="text-center">
							<ButtonShowCode id="divTitleComponent" />
						</div>

						<div id="divTitleComponent" className="hide">
							<Divider className="m-t-xs m-b-sm" />

							<CodeBlock
								text={codeTypography}
								language="jsx"
								showLineNumbers={false}
								theme={dracula}
								codeBlock
								className="component-pre"
								code
							/>
						</div>
					</Card>
				</Col>
				<Col xs={24} sm={24} md={24} lg={24} xl={12}>
					<Card>
						<Space direction="vertical">
							<Typography.Text>Shamcey Theme (default)</Typography.Text>
							<Typography.Text type="secondary">
								Shamcey Theme (secondary)
							</Typography.Text>
							<Typography.Text type="success">
								Shamcey Theme (success)
							</Typography.Text>
							<Typography.Text type="warning">
								Shamcey Theme (warning)
							</Typography.Text>
							<Typography.Text type="danger">
								Shamcey Theme (danger)
							</Typography.Text>
							<Typography.Text disabled>
								Shamcey Theme (disabled)
							</Typography.Text>
							<Typography.Text mark>Shamcey Theme (mark)</Typography.Text>
							<Typography.Text code>Shamcey Theme (code)</Typography.Text>
							<Typography.Text keyboard>
								Shamcey Theme (keyboard)
							</Typography.Text>
							<Typography.Text underline>
								Shamcey Theme (underline)
							</Typography.Text>
							<Typography.Text delete>Shamcey Theme (delete)</Typography.Text>
							<Typography.Text strong>Shamcey Theme (strong)</Typography.Text>
							<Typography.Text italic>Shamcey Theme (italic)</Typography.Text>
							<Typography.Link
								href="https://shamcey.5ppsite.com/"
								target="_blank"
							>
								Shamcey Theme (Link)
							</Typography.Link>
						</Space>

						<Divider className="m-b-xs" orientation="left" plain>
							Text and Link Component
						</Divider>

						<div className="text-center">
							<ButtonShowCode id="divTitleComponent" />
						</div>

						<div id="divTitleComponent" className="hide">
							<Divider className="m-t-xs m-b-sm" />

							<CodeBlock
								text={codeTextAndLink}
								language="jsx"
								showLineNumbers={false}
								theme={dracula}
								codeBlock
								className="component-pre"
								code
							/>
						</div>
					</Card>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={12}>
					<Card>
						<>
							<Paragraph
								editable={{
									onChange: setEditableStr,
								}}
							>
								{editableStr}
							</Paragraph>
							<Paragraph
								editable={{
									onChange: setEditableStrWithSuffix,
									text: editableStrWithSuffix,
								}}
								ellipsis={{
									suffix: editableStrWithSuffixSuffixPart,
								}}
							>
								{editableStrWithSuffixStartPart}
							</Paragraph>
							<Paragraph
								editable={{
									icon: <HighlightOutlined />,
									tooltip: "click to edit text",
									onChange: setCustomIconStr,
								}}
							>
								{customIconStr}
							</Paragraph>
							Trigger edit with:{" "}
							<Radio.Group
								onChange={(e) => setChooseTrigger(radioToState(e.target.value))}
								value={stateToRadio()}
							>
								<Radio value="icon">icon</Radio>
								<Radio value="text">text</Radio>
								<Radio value="both">both</Radio>
							</Radio.Group>
							<Paragraph
								editable={{
									tooltip: "click to edit text",
									onChange: setClickTriggerStr,
									triggerType: chooseTrigger,
								}}
							>
								{clickTriggerStr}
							</Paragraph>
							<Paragraph
								editable={{
									icon: <HighlightOutlined />,
									tooltip: "click to edit text",
									onChange: setCustomEnterIconStr,
									enterIcon: <CheckOutlined />,
								}}
							>
								{customEnterIconStr}
							</Paragraph>
							<Paragraph
								editable={{
									icon: <HighlightOutlined />,
									tooltip: "click to edit text",
									onChange: setNoEnterIconStr,
									enterIcon: null,
								}}
							>
								{noEnterIconStr}
							</Paragraph>
							<Paragraph
								editable={{
									tooltip: false,
									onChange: setHideTooltipStr,
								}}
							>
								{hideTooltipStr}
							</Paragraph>
							<Paragraph
								editable={{
									onChange: setLengthLimitedStr,
									maxLength: 50,
									autoSize: {
										maxRows: 5,
										minRows: 3,
									},
								}}
							>
								{lengthLimitedStr}
							</Paragraph>
							<Typography.Title
								editable
								level={1}
								style={{
									margin: 0,
								}}
							>
								h1. Ant Design
							</Typography.Title>
							<Typography.Title
								editable
								level={2}
								style={{
									margin: 0,
								}}
							>
								h2. Ant Design
							</Typography.Title>
							<Typography.Title
								editable
								level={3}
								style={{
									margin: 0,
								}}
							>
								h3. Ant Design
							</Typography.Title>
							<Typography.Title
								editable
								level={4}
								style={{
									margin: 0,
								}}
							>
								h4. Ant Design
							</Typography.Title>
							<Typography.Title
								editable
								level={5}
								style={{
									margin: 0,
								}}
							>
								h5. Ant Design
							</Typography.Title>
							<Divider />
							<Paragraph copyable>This is a copyable text.</Paragraph>
							<Paragraph
								copyable={{
									text: "Hello, Ant Design!",
								}}
							>
								Replace copy text.
							</Paragraph>
							<Paragraph
								copyable={{
									icon: [
										<SmileOutlined key="copy-icon" />,
										<SmileFilled key="copied-icon" />,
									],
									tooltips: ["click here", "you clicked!!"],
								}}
							>
								Custom Copy icon and replace tooltips text.
							</Paragraph>
							<Paragraph
								copyable={{
									tooltips: false,
								}}
							>
								Hide Copy tooltips.
							</Paragraph>
						</>

						<Divider className="m-b-xs" orientation="left" plain>
							Interactive
						</Divider>

						<div className="text-center">
							<ButtonShowCode id="divInteractive" />
						</div>

						<div id="divInteractive" className="hide">
							<Divider className="m-t-xs m-b-sm" />

							<CodeBlock
								text={codeInteractive}
								language="jsx"
								showLineNumbers={false}
								theme={dracula}
								codeBlock
								className="component-pre"
								code
							/>
						</div>
					</Card>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={12}>
					<Card>
						<>
							<Switch
								checked={ellipsis}
								onChange={() => {
									setEllipsis(!ellipsis);
								}}
							/>

							<Paragraph ellipsis={ellipsis}>
								Ant Design, a design language for background applications, is
								refined by Ant UED Team. Ant Design, a design language for
								background applications, is refined by Ant UED Team. Ant Design,
								a design language for background applications, is refined by Ant
								UED Team. Ant Design, a design language for background
								applications, is refined by Ant UED Team. Ant Design, a design
								language for background applications, is refined by Ant UED
								Team. Ant Design, a design language for background applications,
								is refined by Ant UED Team.
							</Paragraph>

							<Paragraph
								ellipsis={
									ellipsis
										? {
												rows: 2,
												expandable: true,
												symbol: "more",
										  }
										: false
								}
							>
								Ant Design, a design language for background applications, is
								refined by Ant UED Team. Ant Design, a design language for
								background applications, is refined by Ant UED Team. Ant Design,
								a design language for background applications, is refined by Ant
								UED Team. Ant Design, a design language for background
								applications, is refined by Ant UED Team. Ant Design, a design
								language for background applications, is refined by Ant UED
								Team. Ant Design, a design language for background applications,
								is refined by Ant UED Team.
							</Paragraph>

							<Typography.Text
								style={
									ellipsis
										? {
												width: 100,
										  }
										: undefined
								}
								ellipsis={
									ellipsis
										? {
												tooltip: "I am ellipsis now!",
										  }
										: false
								}
							>
								Ant Design, a design language for background applications, is
								refined by Ant UED Team.
							</Typography.Text>
						</>

						<Divider className="m-b-xs" orientation="left" plain>
							Ellipsis
						</Divider>

						<div className="text-center">
							<ButtonShowCode id="divEllipsis" />
						</div>

						<div id="divEllipsis" className="hide">
							<Divider className="m-t-xs m-b-sm" />

							<CodeBlock
								text={codeEllipsis}
								language="jsx"
								showLineNumbers={false}
								theme={dracula}
								codeBlock
								className="component-pre"
								code
							/>
						</div>
					</Card>
				</Col>
			</Row>
		</Card>
	);
}
