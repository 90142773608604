import { Card, Col, Divider, Form, Row, Typography } from "antd";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatInput from "../../../../providers/FloatInput";
import FloatInputMask from "../../../../providers/FloatInputMask";
import { CodeBlock, dracula } from "react-code-blocks";
import {
	codeFloatInput,
	codeFloatInputMask,
	codeFloatQuill,
	codeFloatSelectCode,
} from "./ComponentCodeBox";
import ButtonShowCode from "../Components/ButtonShowCode";

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import {
	formats,
	modulesToolBar,
} from "../../../../providers/reactQuillOptions";
Quill.register("modules/imageResize", ImageResize);

const fieldRequired = {
	required: true,
	message: "This field is required",
};

export default function PageInput() {
	const [form] = Form.useForm();

	const onFinish = (values) => {
		console.log("onFinish", values);
	};

	return (
		<Card className="page-component-input">
			<Form form={form} onFinish={onFinish} initialValues={{ quill: "" }}>
				<Row gutter={[12, 12]}>
					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						More on:{" "}
						<Typography.Link
							href="https://ant.design/components/input/"
							target="new"
						>
							https://ant.design/components/input/
						</Typography.Link>
					</Col>
					<Col xs={24} sm={24} md={24} lg={24} xl={12}>
						<Card>
							<Form.Item name="input" rules={[fieldRequired]}>
								<FloatInput label="Input" placeholder="Input" />
							</Form.Item>

							<Divider className="m-b-xs" orientation="left" plain>
								Input
							</Divider>

							<div className="text-center">
								<ButtonShowCode id="divCodeFloatInput" />
							</div>

							<div id="divCodeFloatInput" className="hide">
								<Divider className="m-t-xs m-b-sm" />

								<CodeBlock
									text={codeFloatInput}
									language="jsx"
									showLineNumbers={false}
									theme={dracula}
									codeBlock
									className="component-pre"
									code
								/>
							</div>
						</Card>

						<Card className="m-t-sm">
							<Form.Item name="phone_number">
								<FloatInputMask
									label="Phone Number"
									placeholder="Phone Number"
									maskLabel="phone_number"
									maskType="999 999 9999"
								/>
							</Form.Item>

							<Divider className="m-b-xs" orientation="left" plain>
								Input Mask
							</Divider>

							<div className="text-center">
								<ButtonShowCode id="divCodeFloatInputMask" />
							</div>

							<div id="divCodeFloatInputMask" className="hide">
								<Divider className="m-t-xs m-b-sm" />

								<CodeBlock
									text={codeFloatInputMask}
									language="jsx"
									showLineNumbers={false}
									theme={dracula}
									codeBlock
									className="component-pre"
									code
								/>
							</div>
						</Card>

						<Card className="m-t-sm">
							<Form.Item
								name="select"
								className="form-select-error"
								rules={[fieldRequired]}
							>
								<FloatSelect
									label="Select"
									placeholder="Select"
									options={[
										{
											value: "Option 1",
											label: "Option 1",
										},
										{
											value: "Option 2",
											label: "Option 2",
										},
										{
											value: "Option 3",
											label: "Option 3",
										},
									]}
								/>
							</Form.Item>

							<Divider className="m-b-xs" orientation="left" plain>
								Select
							</Divider>

							<div className="text-center">
								<ButtonShowCode id="divCodeFloatSelect" />
							</div>

							<div id="divCodeFloatSelect" className="hide">
								<Divider className="m-t-xs m-b-sm" />

								<CodeBlock
									text={codeFloatSelectCode}
									language="jsx"
									showLineNumbers={false}
									theme={dracula}
									codeBlock
									className="component-pre"
									code
								/>
							</div>
						</Card>
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} xl={12}>
						<Card>
							<Form.Item name="quill" rules={[fieldRequired]}>
								<ReactQuill
									theme="snow"
									style={{ height: 200 }}
									modules={modulesToolBar}
									formats={formats}
									placeholder="Quill"
								/>
							</Form.Item>
							<br />
							<br />

							<Divider className="m-b-xs" orientation="left" plain>
								Quill
							</Divider>

							<div className="text-center">
								<ButtonShowCode id="divCodeQuill" />
							</div>

							<div id="divCodeQuill" className="hide">
								<Divider className="m-t-xs m-b-sm" />

								<CodeBlock
									text={codeFloatQuill}
									language="jsx"
									showLineNumbers={false}
									theme={dracula}
									codeBlock
									className="component-pre"
									code
								/>
							</div>
						</Card>
					</Col>
				</Row>
			</Form>
		</Card>
	);
}
