export const codeMainButton = `import { Button } from "antd";

const App = () => {
	return <>
                        <Button className="btn-main">Button</Button>
                        <Button className="btn-main-invert">Button</Button>
                    <>;
};
export default App;
`;

export const codeMainButtonOutline = `import { Button } from "antd";

const App = () => {
	return <>
                        <Button className="btn-main-outline">Button</Button>
                        <Button className="btn-main-invert-outline">Button</Button>
                    <>;
};
export default App;
`;

export const codeButtonBasic = `import { Button, Row, Col, Space } from "antd";

const App = () => {
	return <Row gutter={[12, 12]}>
    <Col xs={24} sm={24} md={12} lg={6}>
        <Space direction="vertical" className="w-100">
            <Button block className="btn-primary">
                Primary
            </Button>
            <Button block className="btn-primary-active">
                Active
            </Button>
            <Button block className="btn-primary-disabled ">
                Disabled
            </Button>
        </Space>
    </Col>
    <Col xs={24} sm={24} md={12} lg={6}>
        <Space direction="vertical" className="w-100">
            <Button block className="btn-secondary">
                Secondary
            </Button>
            <Button block className="btn-secondary-active">
                Active
            </Button>
            <Button block className="btn-secondary-disabled ">
                Disabled
            </Button>
        </Space>
    </Col>
    <Col xs={24} sm={24} md={12} lg={6}>
        <Space direction="vertical" className="w-100">
            <Button block className="btn-success">
                Success
            </Button>
            <Button block className="btn-success-active">
                Active
            </Button>
            <Button block className="btn-success-disabled ">
                Disabled
            </Button>
        </Space>
    </Col>
    <Col xs={24} sm={24} md={12} lg={6}>
        <Space direction="vertical" className="w-100">
            <Button block className="btn-warning">
                Warning
            </Button>
            <Button block className="btn-warning-active">
                Active
            </Button>
            <Button block className="btn-warning-disabled ">
                Disabled
            </Button>
        </Space>
    </Col>
    <Col xs={24} sm={24} md={12} lg={6}>
        <Space direction="vertical" className="w-100">
            <Button block className="btn-danger">
                Danger
            </Button>
            <Button block className="btn-danger-active">
                Active
            </Button>
            <Button block className="btn-danger-disabled ">
                Disabled
            </Button>
        </Space>
    </Col>
    <Col xs={24} sm={24} md={12} lg={6}>
        <Space direction="vertical" className="w-100">
            <Button block className="btn-info">
                Info
            </Button>
            <Button block className="btn-info-active">
                Active
            </Button>
            <Button block className="btn-info-disabled ">
                Disabled
            </Button>
        </Space>
    </Col>
    <Col xs={24} sm={24} md={12} lg={6}>
        <Space direction="vertical" className="w-100">
            <Button block className="btn-indigo">
                Indigo
            </Button>
            <Button block className="btn-indigo-active">
                Active
            </Button>
            <Button block className="btn-indigo-disabled">
                Disabled
            </Button>
        </Space>
    </Col>
    <Col xs={24} sm={24} md={12} lg={6}>
        <Space direction="vertical" className="w-100">
            <Button block className="btn-purple">
                Purple
            </Button>
            <Button block className="btn-purple-active">
                Active
            </Button>
            <Button block className="btn-purple-disabled">
                Disabled
            </Button>
        </Space>
    </Col>
    <Col xs={24} sm={24} md={12} lg={6}>
        <Space direction="vertical" className="w-100">
            <Button block className="btn-pink">
                Pink
            </Button>
            <Button block className="btn-pink-active">
                Active
            </Button>
            <Button block className="btn-pink-disabled">
                Disabled
            </Button>
        </Space>
    </Col>
    <Col xs={24} sm={24} md={12} lg={6}>
        <Space direction="vertical" className="w-100">
            <Button block className="btn-teal">
                Teal
            </Button>
            <Button block className="btn-teal-active">
                Active
            </Button>
            <Button block className="btn-teal-disabled">
                Disabled
            </Button>
        </Space>
    </Col>
    <Col xs={24} sm={24} md={12} lg={6}>
        <Space direction="vertical" className="w-100">
            <Button block className="btn-dark">
                Dark
            </Button>
            <Button block className="btn-dark-active">
                Active
            </Button>
            <Button block className="btn-dark-disabled">
                Disabled
            </Button>
        </Space>
    </Col>
</Row>;
};
export default App;`;

export const codeButtonOutline = `import { Button, Row, Col, Space } from "antd";

const App = () => {
	return <Row gutter={[12, 12]}>
    <Col xs={24} sm={24} md={12} lg={6}>
        <Space direction="vertical" className="w-100">
            <Button block className="btn-primary-outline">
                Primary
            </Button>
            <Button block className="btn-primary-outline-active">
                Active
            </Button>
            <Button block className="btn-primary-outline-disabled ">
                Disabled
            </Button>
        </Space>
    </Col>
    <Col xs={24} sm={24} md={12} lg={6}>
        <Space direction="vertical" className="w-100">
            <Button block className="btn-secondary-outline">
                Secondary
            </Button>
            <Button block className="btn-secondary-outline-active">
                Active
            </Button>
            <Button block className="btn-secondary-outline-disabled ">
                Disabled
            </Button>
        </Space>
    </Col>
    <Col xs={24} sm={24} md={12} lg={6}>
        <Space direction="vertical" className="w-100">
            <Button block className="btn-success-outline">
                Success
            </Button>
            <Button block className="btn-success-outline-active">
                Active
            </Button>
            <Button block className="btn-success-outline-disabled ">
                Disabled
            </Button>
        </Space>
    </Col>
    <Col xs={24} sm={24} md={12} lg={6}>
        <Space direction="vertical" className="w-100">
            <Button block className="btn-warning-outline">
                Warning
            </Button>
            <Button block className="btn-warning-outline-active">
                Active
            </Button>
            <Button block className="btn-warning-outline-disabled ">
                Disabled
            </Button>
        </Space>
    </Col>
    <Col xs={24} sm={24} md={12} lg={6}>
        <Space direction="vertical" className="w-100">
            <Button block className="btn-danger-outline">
                Danger
            </Button>
            <Button block className="btn-danger-outline-active">
                Active
            </Button>
            <Button block className="btn-danger-outline-disabled ">
                Disabled
            </Button>
        </Space>
    </Col>
    <Col xs={24} sm={24} md={12} lg={6}>
        <Space direction="vertical" className="w-100">
            <Button block className="btn-info-outline">
                Info
            </Button>
            <Button block className="btn-info-outline-active">
                Active
            </Button>
            <Button block className="btn-info-outline-disabled ">
                Disabled
            </Button>
        </Space>
    </Col>
    <Col xs={24} sm={24} md={12} lg={6}>
        <Space direction="vertical" className="w-100">
            <Button block className="btn-indigo-outline">
                Indigo
            </Button>
            <Button block className="btn-indigo-outline-active">
                Active
            </Button>
            <Button block className="btn-indigo-outline-disabled">
                Disabled
            </Button>
        </Space>
    </Col>
    <Col xs={24} sm={24} md={12} lg={6}>
        <Space direction="vertical" className="w-100">
            <Button block className="btn-purple-outline">
                Purple
            </Button>
            <Button block className="btn-purple-outline-active">
                Active
            </Button>
            <Button block className="btn-purple-outline-disabled">
                Disabled
            </Button>
        </Space>
    </Col>
    <Col xs={24} sm={24} md={12} lg={6}>
        <Space direction="vertical" className="w-100">
            <Button block className="btn-pink-outline">
                Pink
            </Button>
            <Button block className="btn-pink-outline-active">
                Active
            </Button>
            <Button block className="btn-pink-outline-disabled">
                Disabled
            </Button>
        </Space>
    </Col>
    <Col xs={24} sm={24} md={12} lg={6}>
        <Space direction="vertical" className="w-100">
            <Button block className="btn-teal-outline">
                Teal
            </Button>
            <Button block className="btn-teal-outline-active">
                Active
            </Button>
            <Button block className="btn-teal-outline-disabled">
                Disabled
            </Button>
        </Space>
    </Col>
    <Col xs={24} sm={24} md={12} lg={6}>
        <Space direction="vertical" className="w-100">
            <Button block className="btn-dark-outline">
                Dark
            </Button>
            <Button block className="btn-dark-outline-active">
                Dark
            </Button>
            <Button block className="btn-dark-outline-disabled">
                Dark
            </Button>
        </Space>
    </Col>
</Row>;
};
export default App;`;

export const codeButtonOutlineWithIcon = `import { Button, Row, Col, Space } from "antd";

const App = () => {
	return <Row gutter={[12, 12]}>
    <Col xs={24} sm={24} md={12} lg={6}>
        <Space direction="vertical" className="w-100">
            <Button
                block
                className="btn-primary"
                icon={
                    <FontAwesomeIcon
                        icon={faFontAwesomeFlag}
                        className="m-r-xs"
                    />
                }
            >
                Primary
            </Button>
            <Button
                block
                className="btn-primary-outline"
                icon={
                    <FontAwesomeIcon
                        icon={faFontAwesomeFlag}
                        className="m-r-xs"
                    />
                }
            >
                Primary Outline
            </Button>
        </Space>
    </Col>
    <Col xs={24} sm={24} md={12} lg={6}>
        <Space direction="vertical" className="w-100">
            <Button
                block
                className="btn-success"
                icon={
                    <FontAwesomeIcon
                        icon={faFontAwesomeFlag}
                        className="m-r-xs"
                    />
                }
            >
                Success
            </Button>
            <Button
                block
                className="btn-success-outline"
                icon={
                    <FontAwesomeIcon
                        icon={faFontAwesomeFlag}
                        className="m-r-xs"
                    />
                }
            >
                Success Outline
            </Button>
        </Space>
    </Col>
    <Col xs={24} sm={24} md={12} lg={6}>
        <Space direction="vertical" className="w-100">
            <Button
                block
                className="btn-warning"
                icon={
                    <FontAwesomeIcon
                        icon={faFontAwesomeFlag}
                        className="m-r-xs"
                    />
                }
            >
                Warning
            </Button>
            <Button
                block
                className="btn-warning-outline"
                icon={
                    <FontAwesomeIcon
                        icon={faFontAwesomeFlag}
                        className="m-r-xs"
                    />
                }
            >
                Warning Outline
            </Button>
        </Space>
    </Col>
    <Col xs={24} sm={24} md={12} lg={6}>
        <Space direction="vertical" className="w-100">
            <Button
                block
                className="btn-danger"
                icon={
                    <FontAwesomeIcon
                        icon={faFontAwesomeFlag}
                        className="m-r-xs"
                    />
                }
            >
                Danger
            </Button>
            <Button
                block
                className="btn-danger-outline"
                icon={
                    <FontAwesomeIcon
                        icon={faFontAwesomeFlag}
                        className="m-r-xs"
                    />
                }
            >
                Danger Outline
            </Button>
        </Space>
    </Col>
</Row>;
};
export default App;`;

export const codeButtonWithIconOnly = `import { Button, Row, Col, Space } from "antd";

const App = () => {
	return <Row gutter={[12, 12]}>
    <Col xs={24} sm={24} md={12} lg={12} className="text-center">
        <Space direction="vertical">
            <Space>
                <Button
                    className="btn-primary"
                    icon={<FontAwesomeIcon icon={faFontAwesomeFlag} />}
                />
                <Button
                    className="btn-success"
                    icon={<FontAwesomeIcon icon={faFontAwesomeFlag} />}
                />
                <Button
                    className="btn-warning"
                    icon={<FontAwesomeIcon icon={faFontAwesomeFlag} />}
                />
                <Button
                    className="btn-danger"
                    icon={<FontAwesomeIcon icon={faFontAwesomeFlag} />}
                />
            </Space>
            <Space>
                <Button
                    className="btn-primary-outline-active"
                    icon={<FontAwesomeIcon icon={faFontAwesomeFlag} />}
                />
                <Button
                    className="btn-success-outline-active"
                    icon={<FontAwesomeIcon icon={faFontAwesomeFlag} />}
                />
                <Button
                    className="btn-warning-outline-active"
                    icon={<FontAwesomeIcon icon={faFontAwesomeFlag} />}
                />
                <Button
                    className="btn-danger-outline-active"
                    icon={<FontAwesomeIcon icon={faFontAwesomeFlag} />}
                />
            </Space>
        </Space>
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} className="text-center">
        <Space direction="vertical">
            <Space>
                <Button
                    shape="circle"
                    className="btn-primary"
                    icon={<FontAwesomeIcon icon={faFontAwesomeFlag} />}
                />
                <Button
                    shape="circle"
                    className="btn-success"
                    icon={<FontAwesomeIcon icon={faFontAwesomeFlag} />}
                />
                <Button
                    shape="circle"
                    className="btn-warning"
                    icon={<FontAwesomeIcon icon={faFontAwesomeFlag} />}
                />
                <Button
                    shape="circle"
                    className="btn-danger"
                    icon={<FontAwesomeIcon icon={faFontAwesomeFlag} />}
                />
            </Space>
            <Space>
                <Button
                    shape="circle"
                    className="btn-primary-outline-active"
                    icon={<FontAwesomeIcon icon={faFontAwesomeFlag} />}
                />
                <Button
                    shape="circle"
                    className="btn-success-outline-active"
                    icon={<FontAwesomeIcon icon={faFontAwesomeFlag} />}
                />
                <Button
                    shape="circle"
                    className="btn-warning-outline-active"
                    icon={<FontAwesomeIcon icon={faFontAwesomeFlag} />}
                />
                <Button
                    shape="circle"
                    className="btn-danger-outline-active"
                    icon={<FontAwesomeIcon icon={faFontAwesomeFlag} />}
                />
            </Space>
        </Space>
    </Col>
</Row>;
};
export default App;`;

export const codeButtonGroup = `import { Button, Row, Col, Space } from "antd";

const App = () => {
	return <Row gutter={[12, 12]}>
    <Col xs={24} sm={24} md={12} lg={12} className="text-center">
        <Radio.Group>
            <Radio.Button value="Button 1">Button 1</Radio.Button>
            <Radio.Button value="Button 2">Button 2</Radio.Button>
            <Radio.Button value="Button 3">Button 3</Radio.Button>
        </Radio.Group>
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} className="text-center">
        <Radio.Group>
            <Radio.Button value="Button 1">
                <FontAwesomeIcon icon={faFontAwesomeFlag} />
            </Radio.Button>
            <Radio.Button value="Button 2">
                <FontAwesomeIcon icon={faFontAwesomeFlag} />
            </Radio.Button>
            <Radio.Button value="Button 3">
                <FontAwesomeIcon icon={faFontAwesomeFlag} />
            </Radio.Button>
        </Radio.Group>
    </Col>
</Row>;
};
export default App;`;
